/**
 * @Description: 通用接口请求封装
 * @Date: 2022-10-14
 * @LastEditTime: 2022-10-14
 */
import { Method } from 'axios'
import request from './axios'
import AuthorizationStore from '@/store/modules/authorization'

/**
 * 请求拦截 config配置项
 * @params {string} url 接口名
 * @params {object} data 传参数据
 * @params {string} method 可选，请求方式，默认
 * @params {boolean} codeList 可选，控制自行处理接口响应异常的code码列表，默认为空数组
 */
export function http(
  method: Method,
  host: string,
  apiUrl: string,
  data: any,
  { codeList, headers, baseURL, withCredentials }: any = {}
) {
  // 默认值
  method = method || 'get'
  codeList = codeList || []
  baseURL = baseURL || ''
  headers = headers || {}
  if (!headers['Content-Type']) {
    headers['Content-Type'] = 'application/json; charset=utf-8'
  }

  withCredentials = false // withCredentials === undefined ? true : !!withCredentials

  // url
  let url = ''
  if (process.env.NODE_ENV === 'development') {
    // 本地开发
    url = apiUrl
  } else {
    // 生产服务器
    let origin = ''
    if (host.match(/^http/)) {
      origin = host
    } else {
      if (host.match(/^\/\//)) {
        host = host.slice(2)
      }
      origin = `${window.location.protocol}//${host}`
    }
    if (apiUrl.match(/\/proxy\//)) {
      apiUrl = ''
    }
    if (apiUrl.startsWith('/')) {
      apiUrl = apiUrl.slice(1)
    }
    if (!origin.endsWith('/')) {
      origin = origin + '/'
    }
    url = origin + apiUrl
  }

  // 返回promise
  return new Promise<resultType>((resolve, reject) => {
    request({
      url,
      data,
      method,
      codeList,
      headers,
      baseURL,
      withCredentials,
    })
      .then((res) => {
        resolve(res as unknown as resultType)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export default async function httpWithToken(
  method: Method,
  host: string,
  apiUrl: string,
  data: any,
  { codeList, headers, baseURL, withCredentials }: any = {}
) {
  if (await AuthorizationStore.checkLogin()) {
    headers = headers || {}
    headers.Authorization = 'Bearer ' + AuthorizationStore.token
  }
  return http(method, host, apiUrl, data, { codeList, headers, baseURL, withCredentials })
}
