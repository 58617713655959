/**
 * @Description: 常规
 * @Date: 2022-02-15
 * @LastEditTime: 2022-02-15
 */
import { BusinessUnitType } from '@/types/enums'
import { makeAutoObservable } from 'mobx'

export default class Common {
  _productName: string | null

  get productName() {
    return this._productName
  }

  set productName(val: string | null) {
    this._productName = val
  }

  _businessUnit: BusinessUnitType | null

  get businessUnit() {
    return this._businessUnit
  }

  set businessUnit(val: BusinessUnitType | null) {
    this._businessUnit = val
  }

  sideBarCollapsed: boolean

  constructor() {
    /**
     * state
     */
    this._productName = null
    this._businessUnit = null
    this.sideBarCollapsed = window.localStorage.getItem('sideBarCollapsed') === 'true' || false // 侧边栏是否收起

    makeAutoObservable(this)
  }

  /**
   * computed
   */

  /**
   * action
   */
  setSideBarCollapsed(val: boolean) {
    this.sideBarCollapsed = val
    window.localStorage.setItem('sideBarCollapsed', this.sideBarCollapsed + '')
  }
}
