/**
 * @Description: 常规
 * @Date: 2022-02-15
 * @LastEditTime: 2022-02-15
 */
import { makeAutoObservable } from 'mobx'
interface AccountEditType {
  edit: any
  create: any
}
export default class Account {
  private account: AccountEditType

  constructor() {
    /**
     * state
     */
    this.account = { edit: undefined, create: undefined }

    makeAutoObservable(this)
  }

  /**
   * computed
   */
  get editAccount() {
    return null || this.account.edit
  }

  /**
   * action
   */
  setEditAccount(val: any) {
    this.account.edit = val
  }
}
