import httpwithtoken, { http } from '@/utils/request/http'
import type * as API from '../../types/api'
import config from '@/assets/config'
const host = config.baseUrl // 主机地址，我这边用了代理，就直接空
// 密码登录
export async function login(
  params: API.WikaModalSelectionApplicationContractsSystemsAuthLoginRequestDto
) {
  return http('post', host, '/api/account/login', params)
}
// 短信登录
export async function phoneLogin(params: any) {
  return http('post', host, '/api/account/phoneNumberLoginConfirm', params)
}
// 短信登录获取验证码
export async function getPhoneLoginCode(params: any) {
  return http('post', host, '/api/account/PhoneNumberLoginRequest', params)
}
// 忘记密码获取验证码
export async function getSendCode(params: any) {
  return http('post', host, '/api/account/ResetPasswordRequest', params)
}
// 忘记密码验证验证码
export async function getCheckValidtionCode(params: any) {
  return http('post', host, '/api/account/ResetPasswordCheckValidtionCode', params)
}
// 重置确认提交
export async function getResetPasswordConfirm(params: any) {
  return http('post', host, '/api/account/ResetPasswordConfirm', params)
}
// 注册获取验证码
// 忘记密码获取验证码
export async function getRegisterRequest(params: any) {
  return http('post', host, '/api/account/RegisterRequest', params)
}
// 注册提交
export async function getRegisterConfirm(params: any) {
  return http('post', host, '/api/account/RegisterConfirm', params)
}
// 设置密码提交
export async function getRenewPassword(params: any) {
  return httpwithtoken('post', host, '/api/user/RenewPassword', params)
}
export async function queryUser(
  params: API.WikaModalSelectionApplicationContractsQueryingQueryCriteria
) {
  return httpwithtoken('post', host, '/api/User/GetList', params)
}
export async function updateUser(
  params: API.WikaModalSelectionApplicationContractsBusUsersUserCreateAndUpdateDto
) {
  return httpwithtoken('post', host, '/api/User/Update', params)
}

export async function createUser(
  params: API.WikaModalSelectionApplicationContractsBusUsersUserCreateAndUpdateDto
) {
  return httpwithtoken('post', host, '/api/User/Create', params)
}
export async function deleteUser(id: string) {
  return httpwithtoken('post', host, '/api/User/Delete', { id })
}

export async function getAccessToken(params: any) {
  return http('post', host, '/api/account/renewAccessToken', params)
}
// 修改用户名称
export async function updateUserName(params: any) {
  return httpwithtoken('post', host, '/api/user/UpdateUserName', params)
}
// 修改手机号获取验证码
export async function UpdateUserPhoneNumberRequest(params: any) {
  return httpwithtoken('post', host, '/api/user/UpdateUserPhoneNumberRequest', params)
}
// 修改手机号验证码验证
export async function UpdateUserPhoneNumberConfirm(params: any) {
  return httpwithtoken('post', host, '/api/user/UpdateUserPhoneNumberConfirm', params)
}
// 修改邮箱号获取验证码
export async function UpdateUserMailAddressRequest(params: any) {
  return httpwithtoken('post', host, '/api/user/UpdateUserMailAddressRequest', params)
}
// 修改邮箱号验证码验证
export async function UpdateUserMailAddressConfirm(params: any) {
  return httpwithtoken('post', host, '/api/user/UpdateUserMailAddressConfirm', params)
}
