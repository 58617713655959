import { observer } from '@/hooks/storeHook'
import { Button, Form, Input, message } from 'antd'
import * as API from '@/types/api'
import api from '@/api'
import authorization from '@/store/modules/authorization'
function ResetCom(props: any) {
  const [form] = Form.useForm()
  // 重置
  const onReset = async () => {
    let values = await form.validateFields()
    if (values.password !== values.confirmPassword) {
      message.error('密码不一致，请重新输入！')
      return
    }
    let params: API.WikaModalSelectionApplicationContractsSystemsPasswordSubmitCodeDto = {
      phoneNumberOrMailAddress: props.data.phoneNumberOrMailAddress,
      code: props.data.code,
      password: values.password,
      clientType: 2,
    }
    const result: any = await api.user.getResetPasswordConfirm(params)
    if (result.ok) {
      if (!(await authorization.checkLogin())) {
        props.onHandleLogin()
      } else {
        props.onColse()
      }
    } else {
      message.error(result.errors[0])
    }
  }
  return (
    <div className="register-main form-password">
      <div className="header-title">重置密码</div>
      <div className="password-content">
        <Form className="formWrap" name="login" form={form} size="large">
          <div className="info-title">新密码: </div>
          <Form.Item name="password">
            <Input placeholder="请输入新密码" type="password" className="psd-input" />
          </Form.Item>
          <div className="psd-title psd-line">确认新密码: </div>
          <Form.Item name="confirmPassword">
            <Input placeholder="请再次输入新密码" type="password" className="psd-input" />
          </Form.Item>
          <Form.Item>
            <Button className="psd-btn" onClick={onReset}>
              保存
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  )
}
export default observer(ResetCom)
