import { observer } from '@/hooks/storeHook'
import { Button, Form, Input, message } from 'antd'
import { useState, useEffect } from 'react'
import * as API from '@/types/api'
import api from '@/api'
import authorization from '@/store/modules/authorization'
// import './footerBar.less';

let timeChange: any
function RegisterCom(props: any) {
  const [form] = Form.useForm()
  // 秒数
  const [link, setLink] = useState('init')
  const [count, setCount] = useState(60)
  useEffect(() => {
    clearInterval(timeChange)
    return () => clearInterval(timeChange)
  }, [])
  useEffect(() => {
    if (count > 0 && count < 60) {
      setLink('send')
    } else {
      clearInterval(timeChange)
      setCount(60)
      setLink('init')
    }
  }, [count])

  // 获取验证码
  const handleSendCode = async () => {
    setLink('going')
    let values = await form.validateFields()
    let params: API.WikaModalSelectionApplicationContractsSystemsAuthRegisterPhoneCodeRequestDto = {
      phoneNumberOrMailAddress: values.phoneNumberOrMailAddress,
    }
    const result: any = await api.user.getRegisterRequest(params)
    if (result.ok) {
      // countDown()
      timeChange = setInterval(() => setCount((t) => --t), 1000)
      setLink('send')
    } else {
      setLink('init')
      message.error(result.errors[0])
    }
  }
  // 注册
  const onRegister = async () => {
    let values = await form.validateFields()
    let params: API.WikaModalSelectionApplicationContractsSystemsAuthRegisterSubmitRequestDto = {
      name: values.name,
      phoneNumberOrMailAddress: values.phoneNumberOrMailAddress,
      code: values.code,
      password: values.password,
    }
    const result: any = await api.user.getRegisterConfirm(params)
    if (result.ok) {
      props.onColse()
      // 直接登录
      const values = await form.validateFields()
      let lParam: API.WikaModalSelectionApplicationContractsSystemsAuthLoginRequestDto = {
        PhoneNumberOrMailAddress: values.phoneNumberOrMailAddress,
        password: values.password,
        clientType: 2,
      }
      const loginResult: any = await api.user.login(lParam)
      if (loginResult.ok) {
        authorization.setLoginInfo(
          loginResult.data.userId,
          loginResult.data.name,
          loginResult.data.accessToken,
          new Date(loginResult.data.accessTokenExpiration).getTime(),
          loginResult.data.refreshToken,
          new Date(loginResult.data.refreshTokenExpiration).getTime()
        )
        // 关闭弹框
      } else {
        message.error(loginResult.errors[0])
      }
    } else {
      message.error(result.errors[0])
    }
  }
  const onReturnLogin = () => {
    props.onHandleLogin()
  }
  return (
    <div className="register-main form-password">
      <img className="register-img" src="./logo.png" alt="logo" />
      <div className="password-content">
        <Form className="formWrap" name="login" form={form} size="large">
          <div className="info-title">账号: </div>
          <Form.Item name="phoneNumberOrMailAddress">
            <Input placeholder="请输入邮箱或手机号码" className="psd-input" />
          </Form.Item>
          <div className="info-title psd-title">密码: </div>
          <Form.Item
            name="password"
            // rules={[{ required: true, message: '必填' }]}
          >
            <Input
              placeholder="密码须由6~20位数字、字母、符号其中两种组成"
              className="psd-input"
              type="password"
            />
          </Form.Item>
          <div className="psd-title psd-line">验证码: </div>
          <Form.Item name="code">
            <Input
              placeholder="请输入验证码"
              className="psd-input"
              prefix={
                <button
                  disabled={link !== 'init'}
                  onClick={handleSendCode}
                  className={link === 'init' ? 'send-wait' : 'send'}
                >
                  {link === 'init'
                    ? '发送验证码'
                    : link === 'going'
                    ? '进行中'
                    : `${count}秒后重新发送`}
                </button>
              }
            />
          </Form.Item>
          <div className="psd-title psd-line">用户名：（选填） </div>
          <Form.Item name="name">
            <Input placeholder="请输入用户名" className="psd-input" />
          </Form.Item>
          <Form.Item name="name">
            <Button className="psd-btn" onClick={onRegister}>
              注册
            </Button>
          </Form.Item>
          <Form.Item name="name">
            <div className="login-register">
              已有账号？<a onClick={onReturnLogin}>去登录</a>
            </div>
          </Form.Item>
        </Form>
      </div>
    </div>
  )
}
export default observer(RegisterCom)
