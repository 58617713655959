/**
 * @Description: test模块路由
 * @Date: 2022-11-26
 * @LastEditTime: 2022-11-26
 */
import { RoutesTypeNew } from '@/types/routes'
import { UserSwitchOutlined } from '@ant-design/icons'

const user: RoutesTypeNew = [
  {
    path: 'user',
    component: () => import(/* webpackChunkName: "product-index" */ '@/views/user/index'),
    meta: {
      title: '个人信息',
      icon: <UserSwitchOutlined />,
      noLogin: true,
    },
  },
]

export default user
