/**
 * @Description: 全局状态管理
 * @Date: 2021-12-21
 * @LastEditTime: 2021-12-27
 */
import Common from './modules/common'
import Account from './modules/account'
import Selection from './modules/selection'
export default {
  commonStore: new Common(),
  accountStore: new Account(),
  selectionStore: new Selection(),
}
