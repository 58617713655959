/*
 * @Description: 页面整体布局组件
 * @Date: 2022-02-15
 * @LastEditTime: 2022-02-15
 */
import './index.less'
import { Outlet } from 'react-router-dom'
import HeadBar from './headBar'
import SideBar from './sideBar'
import FooterBar from './footerBar'

function PageLayout() {
  return (
    <div className="c-PageLayout-index">
      <SideBar />

      <div className="appMainWrap">
        <div className="appMain">
          <HeadBar />
          <Outlet />

          <FooterBar />
        </div>
      </div>
    </div>
  )
}

export default PageLayout
