import { observer } from '@/hooks/storeHook'
import { Button, Form, Input, message } from 'antd'
import * as API from '@/types/api'
import api from '@/api'
function EditCom(props: any) {
  const [form] = Form.useForm()
  // 确认修改
  const onEdit = async () => {
    let values = await form.validateFields()
    if (values.newPassword !== values.confirmPassword) {
      message.error('密码不一致，请重新输入！')
      return
    }
    let params: API.WikaModalSelectionApplicationContractsSystemsEditPasswordCodeDto = {
      oldPassword: values.oldPassword,
      newPassword: values.newPassword,
    }
    const result: any = await api.user.getRenewPassword(params)
    if (result.ok) {
      form.setFieldsValue({ oldPassword: '', newPassword: '', confirmPassword: '' })
      props.onColse()
    } else {
      message.error(result.errors[0])
    }
  }
  // 去到忘记密码
  const onGoForget = () => {
    props.onhandleForget()
  }
  return (
    <div className="register-main form-password">
      <div className="header-title">编辑密码</div>
      <div className="password-content">
        <Form className="formWrap" name="login" form={form} size="large">
          <div className="psd-title edit-password">
            <div>原密码:</div> <div onClick={onGoForget}>忘记原密码？</div>
          </div>
          <Form.Item name="oldPassword">
            <Input placeholder="请输入新密码" type="password" className="psd-input" />
          </Form.Item>
          <div className="psd-title psd-line">新密码: </div>
          <Form.Item name="newPassword">
            <Input placeholder="请输入新密码" type="password" className="psd-input" />
          </Form.Item>
          <div className="psd-title psd-line">确认新密码: </div>
          <Form.Item name="confirmPassword">
            <Input placeholder="请再次输入新密码" type="password" className="psd-input" />
          </Form.Item>
          <Form.Item>
            <Button className="psd-btn" onClick={onEdit}>
              保存
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  )
}
export default observer(EditCom)
