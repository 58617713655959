/**
 * @Description: test模块路由
 * @Date: 2022-11-26
 * @LastEditTime: 2022-11-26
 */
import { RoutesTypeNew } from '@/types/routes'
import { UserSwitchOutlined } from '@ant-design/icons'

const product: RoutesTypeNew = [
  {
    path: 'code',
    component: () => import(/* webpackChunkName: "product-index" */ '@/views/code/index'),
    meta: {
      title: '产品编码',
      icon: <UserSwitchOutlined />,
      noLogin: true,
    },
  },
  // {
  //   path: 'code/detail',
  //   component: () => import(/* webpackChunkName: "thermometer" */'@/views/code/detail'),
  //   meta: {
  //     title: '产品详情',
  //     noLogin: true
  //   }
  // }
]

export default product
