export class ProductFeatureResultType {
  id: string
  productId: string
  type: number
  enable: boolean
  code: string | null
  minimum?: number // 经过互动以后，的最小值
  maximum?: number
  operationType: number
  validateStatus?: string | null
  supportedOptionIds: Array<string> | null
  selectedOption?: SelectionFeatureOptionType | null
  constructor(stepItem: ProductStepItemType) {
    let feature = stepItem.feature
    this.id = feature.id
    this.code = null
    this.productId = feature.productId
    this.type = feature.type
    this.operationType = stepItem.operationType
    this.enable = !checkFeatureHasFeatureEnableEvent(feature)
    this.supportedOptionIds = null
    if (this.operationType !== 1 && feature.defaultCode) {
      // not manually edit feature then set default value
      this.code = feature.defaultCode
    }
    if (feature.numberRangeDetail) {
      this.minimum = feature.numberRangeDetail.minimum
      this.maximum = feature.numberRangeDetail.maximum
    }

    if (feature.options != null && feature.options.length > 0) {
      this.supportedOptionIds = feature.options.map((option) => option.id)
      feature.options.forEach((option) => {
        if (this.operationType !== 1 && option.defaultOption) {
          // not manually edit feature then set default value
          this.selectedOption = option
          this.code = option.code
        }
      })
    }
  }
}

export function checkFeatureHasFeatureEnableEvent(feature: ProductFeatureType): boolean {
  if (feature.actions && feature.actions.length > 0) {
    let find = false
    feature.actions.forEach((a) => {
      if (a.actionType === 4) {
        find = true
        return true
      }
    })
    return find
  }
  return false
}
