import http from '@/utils/request/http'
import config from '@/assets/config'
const host = config.baseUrl
// 获取所有行业
export async function GetAllIndustry() {
  return http('get', host, 'api/industryType/GetIndutries', {})
}
// 产品编号申请
export async function CreateProductOrderRequest(params: any) {
  return http('post', host, 'api/order/CreateProductOrderRequest', params)
}
// 获取行业下的产品
export async function GetProductsByIndustryId(industryId: any) {
  return http('get', host, 'api/product/GetProductsByIndustryId', { industryId })
}

// 获取行业下的产品
export async function SearchProducts(key: string) {
  return http('get', host, 'api/product/SearchProducts', { key })
}
