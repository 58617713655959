/**
 * @Description: 用户模块
 * @Date: 2021-12-21
 * @LastEditTime: 2021-12-27
 */
import { makeAutoObservable, action } from 'mobx'
import * as API from '@/types/api'
import { http } from '@/utils/request/http'
import config from '@/assets/config'
const host = config.baseUrl

interface TokenInfo {
  token: string
  expiredTime: number
}

interface UserInfo {
  id: string
  name: string
}

class Authorization {
  private accessTokenKey = 'WIKAAccessToken'
  private refreshTokenKey = 'WIKARefreshToken'
  private userInfoKey = 'WIKAUserInfo'
  private accessToken: TokenInfo | null
  private refreshToken: TokenInfo | null
  userInfo: UserInfo | null

  constructor() {
    /**
     * state
     */
    this.userInfo = null
    this.accessToken = null
    this.refreshToken = null
    const nowTime = new Date().getTime()
    const accessTokenString = localStorage.getItem(this.accessTokenKey)
    if (accessTokenString) {
      let data: TokenInfo = JSON.parse(accessTokenString)
      if (data && data.expiredTime > nowTime) {
        this.accessToken = data
      }
    }

    const refreshTokenString = localStorage.getItem(this.refreshTokenKey)
    if (refreshTokenString) {
      let data: TokenInfo = JSON.parse(refreshTokenString)
      if (data && data.expiredTime > nowTime) {
        this.refreshToken = data
        // this.updateAccessToken();
      }
    }
    if (this.accessToken) {
      const userInfoString = localStorage.getItem(this.userInfoKey)
      if (userInfoString) {
        let data: UserInfo = JSON.parse(userInfoString)
        this.userInfo = data
      }
    }

    makeAutoObservable(this)
  }

  /**
   * computed
   */
  // 是否已登录
  get isAccessTokenValid() {
    const nowTime = new Date().getTime()
    if (this.accessToken && this.accessToken.expiredTime > nowTime) {
      return true
    }
    return false
  }

  @action checkLogin = async () => {
    const nowTime = new Date().getTime()
    if (this.accessToken) {
      if (this.accessToken.expiredTime > nowTime) {
        return true
      }
    } // refreshToken时间
    if (this.refreshToken) {
      await this.updateAccessToken()
      return this.isAccessTokenValid
    }
    return false
  }

  @action setLoginInfo(
    userId: string,
    userName: string,
    accessToken: string,
    accessTokenExpiredTime: number,
    refreshToken: string,
    refreshTokenExpiredTime: number
  ) {
    this.accessToken = {
      token: accessToken,
      expiredTime: accessTokenExpiredTime,
    }
    localStorage.setItem(this.accessTokenKey, JSON.stringify(this.accessToken))

    this.refreshToken = {
      token: refreshToken,
      expiredTime: refreshTokenExpiredTime,
    }
    localStorage.setItem(this.refreshTokenKey, JSON.stringify(this.refreshToken))

    this.userInfo = {
      id: userId,
      name: userName,
    }

    localStorage.setItem(this.userInfoKey, JSON.stringify(this.userInfo))
  }

  @action refreshAccessToken(
    accessToken: string,
    accessTokenExpiredTime: number,
    refreshTokenExpiredTime: number
  ) {
    this.accessToken = {
      token: accessToken,
      expiredTime: accessTokenExpiredTime,
    }
    localStorage.setItem(this.accessTokenKey, JSON.stringify(this.accessToken))

    if (this.refreshToken) {
      this.refreshToken.expiredTime = refreshTokenExpiredTime
      localStorage.setItem(this.refreshTokenKey, JSON.stringify(this.refreshToken))
    }
  }

  @action loginOut() {
    this.accessToken = null
    this.refreshToken = null
    this.userInfo = null
    localStorage.removeItem(this.accessTokenKey)
    localStorage.removeItem(this.refreshTokenKey)
    localStorage.removeItem(this.userInfoKey)
  }

  @action updateAccessToken = async () => {
    const nowTime = new Date().getTime()
    if (this.refreshToken && this.refreshToken.expiredTime > nowTime) {
      let params: API.WikaModalSelectionApplicationContractsSystemsAuthLoginTokenDto = {
        refreshToken: this.refreshToken.token,
        clientType: 2,
      }
      const res: any = await http('post', host, '/api/account/renewAccessToken', params)
      if (res.ok) {
        // console.log('access updating: ' + res.data.accessTokenExpiration)
        this.accessToken = {
          token: res.data.accessToken,
          expiredTime: new Date(res.data.accessTokenExpiration).getTime(),
        }
        localStorage.setItem(this.accessTokenKey, JSON.stringify(this.accessToken))
        this.refreshToken.expiredTime = new Date(res.data.refreshTokenExpiration).getTime()
        localStorage.setItem(this.refreshTokenKey, JSON.stringify(this.refreshToken))
      }
    }
  }

  get token() {
    return this.accessToken?.token
  }

  get getRefreshToken() {
    return this.refreshToken?.token
  }
}

export default new Authorization()
