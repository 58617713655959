/**
 * @Description: 公共api接口集合
 * @Date: 2022-10-14
 * @LastEditTime: 2021-12-28
 */
// import http from '@/utils/request/http'
import { SortOrder } from 'antd/lib/table/interface'
import * as user from './modules/account'
import * as feature from './modules/feature'
import * as product from './modules/product'
import * as industry from './modules/industry'
import type {
  WikaModalSelectionApplicationContractsQueryingSortDirection,
  WikaModalSelectionApplicationContractsQueryingQueryFilter,
} from '../types/api'
import { ReactText } from 'react'
const api = {
  getOffset: function (current: any, pageSize: any): number {
    return ((current || 1) - 1) * (pageSize || 1)
  },
  getLimit: function (pageSize: any): number {
    return pageSize || 1
  },
  getSortBy: function (sort: Record<string, SortOrder>): string | null {
    // sort {lastModificationTime:"ascend"}
    let rev: string | null = ''
    for (let p in sort) {
      if (p) {
        rev = p
        break
      }
    }
    return rev
  },
  getSortDirection: function (
    sort: Record<string, SortOrder>
  ): WikaModalSelectionApplicationContractsQueryingSortDirection {
    // sort {lastModificationTime:"ascend"}
    let rev: WikaModalSelectionApplicationContractsQueryingSortDirection = 0
    for (let p in sort) {
      if (sort[p] == null || sort[p] === 'descend') {
        rev = 1
      } else {
        rev = 0
      }
    }
    return rev
  },
  getFilter: function (
    filter: Record<string, ReactText[] | null>,
    search?: any,
    keys?: string[]
  ): WikaModalSelectionApplicationContractsQueryingQueryFilter[] | undefined {
    let rev: WikaModalSelectionApplicationContractsQueryingQueryFilter[] = []
    let isFind = false
    for (let f in filter) {
      if (filter[f] !== null) {
        let item: WikaModalSelectionApplicationContractsQueryingQueryFilter = {
          field: f,
          value: (filter[f] as unknown as string[])[0], // 使用或者
        }
        rev.push(item)
        isFind = true
      }
    }
    if (search && keys && keys.length > 0) {
      for (let k of keys) {
        if (search[k]) {
          let item: WikaModalSelectionApplicationContractsQueryingQueryFilter = {
            field: k,
            value: search[k] as string,
            // 这里需要使用and
          }
          rev.push(item)
          isFind = true
        }
      }
    }

    if (isFind) {
      return rev
    }
    return undefined
  },
  user,
  feature,
  product,
  industry,
}

export default api
