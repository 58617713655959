import { useEffect } from 'react'
import { Button, Form, Input, message } from 'antd'
import * as API from '@/types/api'
import api from '@/api'
import authorization from '@/store/modules/authorization'

function Index(props: any) {
  // form实例
  const [form] = Form.useForm()
  // const [isModalOpen, setIsModalOpen] = useState(true);
  useEffect(() => {
    ;(async () => {
      form.setFieldsValue({
        userName: props.userName,
      })
    })()
  }, [])
  const onSave = async () => {
    let values = await form.validateFields()
    let params: API.WikaModalSelectionApplicationContractsSystemsAuthUserInfoNameRequestDto = {
      userName: values.userName,
      clientType: 2,
      refreshToken: authorization.getRefreshToken,
    }
    const res: any = await api.user.updateUserName(params)
    if (res.ok) {
      authorization.refreshAccessToken(
        res.data.accessToken,
        new Date(res.data.accessTokenExpiration).getTime(),
        new Date(res.data.refreshTokenExpiration).getTime()
      )
      form.setFieldsValue({ userName: '' })
      props.getUserInfo()
      props.onColse()
    } else {
      message.error(res.errors[0])
      // props.onClose();
    }
  }

  return (
    <div className="register-main form-password">
      <div className="header-title">编辑用户名</div>
      <div className="password-content">
        <Form className="formWrap" name="login" form={form} size="large">
          <div className="info-title psd-title">用户名: </div>
          <Form.Item name="userName">
            <Input placeholder="请输入用户名" className="psd-input" />
          </Form.Item>
          <Form.Item>
            <Button className="psd-btn" onClick={onSave}>
              保存
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  )
}

export default Index
