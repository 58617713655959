/**
 * @Description: 全局入口组件
 * @Date: 2021-12-27
 * @LastEditTime: 2022-02-17
 */

import 'antd/dist/antd.min.css'
import { HashRouter } from 'react-router-dom'
import { routes, onRouteBefore } from '@/router'
import RouterWaiter from 'react-router-waiter'

function App() {
  return (
    <HashRouter>
      <RouterWaiter routes={routes} onRouteBefore={onRouteBefore} />
    </HashRouter>
  )
}

export default App
