/**
 * @Description: 通用工具函数
 * @Description: （通用工具函数：即放到任何项目里都能用的）
 * @Description: （业务相关函数请放在同目录下的appTools
 * @Description: （函数请添加详细注释）
 * @Date: 2022-03-18
 * @LastEditTime: 2022-03-21
 */
import { tools } from 'ut-utils' // https://www.npmjs.com/package/ut-utils

const deft = {}

export default {
  ...deft,
  ...tools,
}
