import { useState, useEffect } from 'react'
import { Button, Modal, Form, Input, message } from 'antd'
import './index.less'
import './loginModal.less'
import * as API from '@/types/api'
import api from '@/api'
import authorization from '@/store/modules/authorization'
import RegisterCom from '../../components/LoginRelated/registerCom'
import ForgetCom from '../../components/LoginRelated/forgetCom'
import ResetCom from '../../components/LoginRelated/resetCom'
import EditCom from '../../components/LoginRelated/editCom'
import UsernameCom from '../../components/LoginRelated/usernameCom'
import UserphoneCom from '../../components/LoginRelated/userphoneCom'
import UseremailCom from '@/components/LoginRelated/useremailCom'

let timeChange: any

function Index(data: any) {
  // form实例
  const [form] = Form.useForm()
  // 切换状态
  const [changeStatus, setChangeStatus] = useState(true)
  // 秒数（登录）
  // 秒数（忘记密码）
  const [loginLink, setLoginLink] = useState('init')
  const [loginCount, setLoginCount] = useState(60)
  const [resetData, setResetData] = useState<any>({})
  // 跳转值判断
  const [flagValue, setFlagValue] = useState(data.type)

  useEffect(() => {
    setFlagValue(data.type)
    if (data.type === 'login') {
      if (
        window.localStorage.getItem('phoneNumberOrMailAddress') &&
        window.localStorage.getItem('password')
      ) {
        form.setFieldsValue({
          phoneNumberOrMailAddress: window.localStorage.getItem('phoneNumberOrMailAddress'),
          password: window.localStorage.getItem('password'),
        })
      }
    }
  }, [data.isModalOpen])
  useEffect(() => {
    clearInterval(timeChange)
    return () => clearInterval(timeChange)
  }, [])
  useEffect(() => {
    if (loginCount > 0 && loginCount < 60) {
      // setBtnContent(`${time}s后重发`);
      setLoginLink('send')
    } else {
      clearInterval(timeChange)
      setLoginCount(60)
      setLoginLink('init')
      // setBtnContent('获取验证码');
    }
  }, [loginCount])
  const handleCancel = () => {
    // setFlagValue('login')
    data.handleClose()
  }
  // 登录
  const onSubmit = async () => {
    const values = await form.validateFields()
    let lParam: API.WikaModalSelectionApplicationContractsSystemsAuthLoginRequestDto = {
      PhoneNumberOrMailAddress: values.phoneNumberOrMailAddress,
      password: values.password,
      clientType: 2,
    }
    let params: API.WikaModalSelectionApplicationContractsSystemsAuthLoginPhoneRequestDto = {
      phoneNumber: values.phoneNumber,
      code: values.code,
    }
    const result: any = changeStatus
      ? await api.user.login(lParam)
      : await api.user.phoneLogin(params)
    if (result.ok) {
      authorization.setLoginInfo(
        result.data.userId,
        result.data.name,
        result.data.accessToken,
        new Date(result.data.accessTokenExpiration).getTime(),
        result.data.refreshToken,
        new Date(result.data.refreshTokenExpiration).getTime()
      )
      // 关闭弹框
      data.handleClose()
    } else if (result.errorCode === '6006' || result.errorCode === '6007') {
      message.warning('此账号需要重新设置密码。')
      setFlagValue('forget')
    } else {
      message.error(result.errors[0])
    }
  }

  // 登录获取验证码
  const handleLoginSendCode = async () => {
    // getPhoneLoginCode
    setLoginLink('going')
    const values = await form.validateFields()
    let params: API.WikaModalSelectionApplicationContractsSystemsAuthLoginPhoneCodeRequestDto = {
      phoneNumber: values.phoneNumber,
      clientType: 2,
    }
    const result: any = await api.user.getPhoneLoginCode(params)
    if (result.ok) {
      timeChange = setInterval(() => setLoginCount((t) => --t), 1000)
      setLoginLink('send')
    } else {
      setLoginLink('init')
      message.error(result.errors[0])
    }
  }
  // 登录切换状态
  const onCheck = () => {
    setChangeStatus(!changeStatus)
  }
  // 忘记密码
  const handleForget = () => {
    setFlagValue('forget')
  }
  // 返回登录页面
  const onReturnLogin = async () => {
    setFlagValue('login')
  }
  // 下一步去重置密码页
  const onResetPassword = () => {
    setFlagValue('reset')
  }
  // 存忘记密码的值
  const savePasswordValues = (values: any) => {
    setResetData(values)
  }
  // 注册
  const onGoRegister = async () => {
    setFlagValue('register')
  }

  // 注册参数
  const registerProps = {
    onHandleLogin: onReturnLogin,
    onColse: handleCancel,
  }
  // 忘记密码参数
  const passwordProps = {
    onForgetPassword: onResetPassword,
    onSaveValue: savePasswordValues,
    onColse: handleCancel,
  }
  // 重置密码参数
  const resetProps = {
    onHandleLogin: onReturnLogin,
    onColse: handleCancel,
    data: resetData,
  }
  // 编辑密码
  const editProps = {
    onHandleLogin: onReturnLogin,
    onhandleForget: handleForget,
    onColse: handleCancel,
  }
  const nameProps = {
    getUserInfo: data.handleGetUser,
    onColse: handleCancel,
  }
  const phoneProps = {
    getUserInfo: data.handleGetUser,
    onColse: handleCancel,
  }
  const emailProps = {
    getUserInfo: data.handleGetUser,
    onColse: handleCancel,
  }

  return (
    <div className="login-main">
      <Modal open={data.isModalOpen} footer={null} onCancel={handleCancel} className="modal-home">
        <div className="modal-main">
          {flagValue === 'login' && (
            <div className="form-main">
              <img src="./logo.png" alt="logo" />
              <Form
                className="formWrap"
                name="login"
                form={form}
                size="large"
                // initialValues={{ username: '33332@e.com', password: '123456', remember: true }}
              >
                {changeStatus && (
                  <div>
                    <div className="info-title">账号: </div>
                    <Form.Item
                      name="phoneNumberOrMailAddress"
                      rules={[{ required: true, message: '必填' }]}
                    >
                      <Input placeholder="请输入邮箱或手机号码" />
                    </Form.Item>

                    <div className="info-title">密码: </div>
                    <Form.Item name="password" rules={[{ required: true, message: '必填' }]}>
                      <Input type="password" placeholder="请输入密码" />
                    </Form.Item>
                  </div>
                )}
                {!changeStatus && (
                  <div>
                    <div className="info-title">手机号码: </div>
                    <Form.Item
                      name="phoneNumber"
                      // rules={[{ required: true, message: '必填' }]}
                    >
                      <Input placeholder="请输入手机号码" className="psd-input" />
                    </Form.Item>

                    <div className="info-title">验证码: </div>
                    <Form.Item
                      name="code" // rules={[{ required: true, message: '必填' }]}
                    >
                      <Input
                        placeholder="请输入验证码"
                        className="psd-input"
                        prefix={
                          <button
                            disabled={loginLink !== 'init'}
                            className={loginLink === 'init' ? 'send-wait' : 'send'}
                            onClick={handleLoginSendCode}
                          >
                            {loginLink === 'init'
                              ? '发送验证码'
                              : loginLink === 'going'
                              ? '进行中'
                              : `${loginCount}秒后重新发送`}
                          </button>
                        }
                      />
                    </Form.Item>
                  </div>
                )}
                <Form.Item>
                  <Button className="loginBtn" onClick={onSubmit}>
                    登录
                  </Button>
                </Form.Item>
                <Form.Item style={{ margin: 0 }}>
                  <div className="moreWrap">
                    <div className="btn-right">
                      <a className="login-form-forgot" onClick={handleForget}>
                        忘记密码?
                      </a>
                    </div>
                  </div>
                </Form.Item>
                <Form.Item className="item-line">
                  <div className="btn-line">
                    <span className="line line-left"></span>
                    <span className="line-none">或者</span>
                    <span className="line line-right"></span>
                  </div>
                </Form.Item>
                <Form.Item>
                  <Button className="checkout-Btn" onClick={onCheck}>
                    {changeStatus ? '手机验证码登录' : '账号登录'}
                  </Button>
                </Form.Item>
                <Form.Item>
                  <div className="login-register">
                    还没有账号？<a onClick={onGoRegister}>去注册</a>
                  </div>
                </Form.Item>
              </Form>
            </div>
          )}
          {flagValue === 'forget' && <ForgetCom {...passwordProps} />}
          {flagValue === 'reset' && <ResetCom {...resetProps} />}
          {flagValue === 'register' && <RegisterCom {...registerProps} />}
          {flagValue === 'edit' && <EditCom {...editProps} />}
          {flagValue === 'username' && <UsernameCom {...nameProps} />}
          {flagValue === 'phone' && <UserphoneCom {...phoneProps} />}
          {flagValue === 'email' && <UseremailCom {...emailProps} />}
        </div>
      </Modal>
    </div>
  )
}

export default Index
