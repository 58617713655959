import { observer } from '@/hooks/storeHook'
import { Button, Form, Input, message } from 'antd'
import { useState, useEffect } from 'react'
import * as API from '@/types/api'
import api from '@/api'
let timeChange: any
function ForgetCom(props: any) {
  const [form] = Form.useForm()
  // 秒数（忘记密码）
  const [link, setLink] = useState('init')
  const [count, setCount] = useState(60)
  useEffect(() => {
    clearInterval(timeChange)
    return () => clearInterval(timeChange)
  }, [])

  useEffect(() => {
    if (count > 0 && count < 60) {
      setLink('send')
    } else {
      clearInterval(timeChange)
      setCount(60)
      setLink('init')
    }
  }, [count])

  // 获取验证码
  const handleSendCode = async () => {
    setLink('going')
    let values = await form.validateFields()
    let params: API.WikaModalSelectionApplicationContractsSystemsAuthPasswordCodeRequestDto = {
      phoneNumberOrMailAddress: values.phoneNumberOrMailAddress,
      clientType: 2,
    }
    const result: any = await api.user.getSendCode(params)
    if (result.ok) {
      // countDown()
      timeChange = setInterval(() => setCount((t) => --t), 1000)
      setLink('send')
    } else {
      setLink('init')
      message.error(result.errors[0])
    }
  }
  // 忘记密码（下一步）
  const onNext = async () => {
    let values = await form.validateFields()
    let params: API.WikaModalSelectionApplicationContractsSystemsPasswordCheckValidtionCodeDto = {
      phoneNumberOrMailAddress: values.phoneNumberOrMailAddress,
      code: values.code,
    }
    const result: any = await api.user.getCheckValidtionCode(params)
    if (result.ok) {
      props.onSaveValue(values)
      props.onForgetPassword()
    } else {
      message.error(result.errors[0])
    }
  }
  return (
    <div className="register-main form-password">
      <div className="header-title">忘记密码</div>
      <div className="password-content">
        <Form className="formWrap" name="login" form={form} size="large">
          <div className="info-title psd-title">账号: </div>
          <Form.Item name="phoneNumberOrMailAddress">
            <Input placeholder="请输入邮箱或手机号码" className="psd-input" />
          </Form.Item>
          <div className="psd-title psd-line">验证码: </div>
          <Form.Item name="code">
            <Input
              placeholder="请输入验证码"
              className="psd-input"
              prefix={
                <button
                  disabled={link !== 'init'}
                  onClick={handleSendCode}
                  className={link === 'init' ? 'send-wait' : 'send'}
                >
                  {link === 'init'
                    ? '发送验证码'
                    : link === 'going'
                    ? '进行中'
                    : `${count}秒后重新发送`}
                </button>
              }
            />
          </Form.Item>
          <Form.Item>
            <Button className="psd-btn" onClick={onNext}>
              下一步
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  )
}
export default observer(ForgetCom)
