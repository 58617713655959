/*
 * @Description: 顶部栏
 * @Date: 2022-02-15
 * @LastEditTime: 2022-02-24
 */
import './headBar.less'
import { useStore, observer } from '@/hooks/storeHook'
import { Breadcrumb } from 'antd'
import { useLocation, useNavigate, Link } from 'react-router-dom'
import { getRouteMetaMap } from '@/utils/appTools'
import { BusinessUnit, BusinessUnitLevelTree, BusinessUnitName } from '@/types/enums'
import * as R from 'ramda'

function HeadBar() {
  const { commonStore } = useStore()

  const location = useLocation()
  const navigate = useNavigate()

  /**
   * 面包屑
   */
  const routeMetaMap = getRouteMetaMap()
  const pathSnippets = location.pathname.split('/').filter((i) => i)
  const extraBreadcrumbItems = pathSnippets.map((_, index) => {
    const url = `/${pathSnippets.slice(0, index + 1).join('/')}`
    let routerItem = routeMetaMap[url]
    const searchParam = new URLSearchParams()
    if (!routerItem) {
      routerItem = routeMetaMap[url + '/:id']
    }
    if (routerItem) {
      if (url === '/home') {
        return null // 首页不显示头
      }
      let title = routerItem.title
      if (
        routerItem.dynamicTitleType &&
        routerItem.dynamicTitleType === 'product' &&
        commonStore.productName
      ) {
        title = commonStore.productName
      }
      if (
        routerItem.dynamicTitleType &&
        routerItem.dynamicTitleType === 'businessUnit' &&
        commonStore.businessUnit
      ) {
        const kind =
          R.keys(BusinessUnitLevelTree).filter((key) =>
            R.pipe(
              () => BusinessUnitLevelTree[key],
              (value) => value.find((value) => value === commonStore.businessUnit)
            )()
          )[0] || BusinessUnit.Thermometer
        title = BusinessUnitName[kind]
        searchParam.append('kind', BusinessUnit[kind])
      }
      return (
        <Breadcrumb.Item key={url}>
          <a
            onClick={() =>
              navigate(`${url}${searchParam.size > 0 ? `?${searchParam.toString()}` : ''}`)
            }
          >
            {title}
          </a>
        </Breadcrumb.Item>
      )
    } else {
      return null
    }
  })

  function toPageHome() {
    navigate('/home')
  }

  return (
    <div className="c-PageLayout-headBar">
      <div className="header-wrapper">
        <div className="headLeft">
          <Breadcrumb separator=">">
            <Breadcrumb.Item key="index">
              <a onClick={toPageHome}>首页</a>
            </Breadcrumb.Item>
            {extraBreadcrumbItems}
          </Breadcrumb>
        </div>
        <Link className="headRight" to="/home">
          <img src="/logo.png" alt="logo" />
        </Link>
      </div>
    </div>
  )
}

export default observer(HeadBar)
