import http from '@/utils/request/http'
import config from '@/assets/config'
import { BusinessUnitType } from '@/types/enums'
const host = config.baseUrl
export const GetProductInfoById = (productId: any) =>
  http('get', host, 'api/product/GetProductInfoById', { productId })

export const GetBusinessUnitTypeList = (businessUnitType: BusinessUnitType) => (params: any) =>
  http('post', host, `api/product/Get${BusinessUnitType[businessUnitType]}List`, params)

// 收藏
export const GetStarProduct = (params: any) => http('post', host, 'api/product/StarProduct', params)

// 取消收藏
export const GetUnStarProduct = (params: any) =>
  http('post', host, 'api/product/UnStarProduct', params)

// 获取收藏数据
export const GetStarProductList = (params: any) =>
  http('get', host, 'api/product/GetStarProductList', params)

// 获取历史记录数据
export const GetVisitedProducts = (params: any) =>
  http('get', host, 'api/user/GetVisitedProducts', params)

// 获取选型记录
export const GetSelectionHistory = (params: any) =>
  http('get', host, 'api/user/GetSelectionHistory', params)

// 获取个人信息
export const GetUserInfo = () => http('get', host, 'api/user/GetUserInfo', {})

// 下载文件
export const DownloadSelectionResult = (requestId: any) =>
  http('get', host, 'api/file/DownloadSelectionResult', { requestId })
