import http from '@/utils/request/http'
import config from '@/assets/config'
const host = config.baseUrl // 主机地址，我这边用了代理，就直接空
export async function getFeatureListByProductId(productId: string) {
  return http('get', host, 'api/feature/GetSelectionDataByProductId', { productId })
}

export async function getProductSelectionStepsById(productId: string) {
  return http('get', host, 'api/feature/GetProductSelectionStepsById', { productId })
}
// 最后一步提交数据
export async function completeSelection(params: any) {
  return http('post', host, 'api/feature/CompleteSelection', params)
}
// 获取护套的feature
export async function getThermowellSelectionFeaturesById(productId: string, thermowellId: string) {
  return http('get', host, 'api/feature/GetThermowellSelectionFeaturesById', {
    productId,
    thermowellId,
  })
}

/**
 * 获取产品的图组
 * @param productId
 * @returns
 */
export async function getProductDisplayGroups(productId: string) {
  return http('get', host, 'api/feature/GetProductDisplayGroups', { productId })
}

export async function remarkSelection(requestId: string, remarkWord: string) {
  return http('post', host, 'api/feature/RemarkSelection', { requestId, remarkWord })
}
