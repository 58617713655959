/**
 * @Description: test模块路由
 * @Date: 2022-11-26
 * @LastEditTime: 2022-11-26
 */
import { RoutesTypeNew } from '@/types/routes'
import { UserSwitchOutlined } from '@ant-design/icons'

const Common: RoutesTypeNew = [
  {
    path: '/account-info',
    component: () => import('@/views/index/accountInfo'),
    meta: {
      title: '账户信息',
      icon: <UserSwitchOutlined />,
      noLogin: true,
    },
  },
  {
    path: '/policy',
    component: () => import('@/views/index/policy'),
    meta: {
      title: '隐私政策',
      noLogin: true,
    },
  },
  {
    path: '/copyright',
    component: () => import('@/views/index/copyright'),
    meta: {
      title: '隐私政策',
      noLogin: true,
    },
  },
  {
    path: '/terms',
    component: () => import('@/views/index/terms'),
    meta: {
      title: '条款信息',
      noLogin: true,
    },
  },
]

export default Common
