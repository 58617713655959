/**
 * @Description: 路由统一配置
 * @Date: 2022-10-14
 * @LastEditTime: 2022-03-29
 */
import PageLayout from '@/components/PageLayout'
import { RoutesTypeNew } from '@/types/routes'
import { OnRouteBeforeType } from 'react-router-waiter'
import authorization from '@/store/modules/authorization'

// 导入模块路由
import product from './product'
import industry from './industry'
import code from './code'
import user from './user'
import common from './common'
/**
 * @description: 全局路由配置
 * meta字段说明：↓↓↓
 * @param {string} title // 路由页面标题，以及侧边栏菜单中的标题
 * @param {element} icon // 侧边栏该路由菜单显示的图标
 * @param {string} accessId // 路由页面权限id
 * @param {boolean} noLogin // 路由页面是否需要登录访问
 * @param {boolean} hideMenu // 是否在侧边栏中隐藏该路由菜单
 */
const routes: RoutesTypeNew = [
  {
    path: '/',
    redirect: '/home',
  },
  {
    path: '/',
    element: <PageLayout />,
    children: [
      // {
      //   path: 'index',
      //   component: () => import(/* webpackChunkName: "index" */ '@/views/index/index'),
      //   meta: {
      //     title: '首页',
      //     icon: <HomeOutlined />,
      //     noLogin: true,
      //   },
      // },
      ...common,
      ...product,
      ...industry,
      ...code,
      ...user,
    ],
  },
  {
    path: '/home',
    component: () => import(/* webpackChunkName: "login" */ '@/views/index/index'),
    meta: {
      title: '首页',
      noLogin: true,
      hideMenu: true,
    },
  },
  {
    path: '/login',
    component: () => import(/* webpackChunkName: "login" */ '@/views/login/index'),
    meta: {
      title: '登录',
      noLogin: true,
      hideMenu: true,
    },
  },
  {
    path: '/403',
    component: () => import(/* webpackChunkName: "errorPage" */ '@/views/errorPage/page403'),
    meta: {
      title: '403',
      noLogin: true,
      hideMenu: true,
    },
  },
  {
    path: '*',
    component: () => import(/* webpackChunkName: "errorPage" */ '@/views/errorPage/page404'),
    meta: {
      title: '404',
      noLogin: true,
      hideMenu: true,
    },
  },
  /*
  { // 仅用于侧边栏外链菜单
    url: 'https://github.com/neohan666/react-antd-mobx-admin',
    meta: {
      title: 'GitHub',
      icon: <GithubOutlined />,
    }
  } */
]

/**
 * @description: 全局路由拦截
 * @param {string} pathname 当前路由路径
 * @param {object} meta 当前路由自定义meta字段
 * @return {string} 需要跳转到其他页时就return一个该页的path路径
 */
const onRouteBefore: OnRouteBeforeType = async ({ meta }) => {
  // 动态修改页面title
  if (meta.title !== undefined) {
    document.title = meta.title + '-WIKA-威卡选型系统'
  }

  // 登录及权限判断
  if (!meta.noLogin && !(await authorization.checkLogin())) {
    // 路由是否需要登录
    return `/login?redirectUrl=${encodeURIComponent(window.location.href)}`
  }
}

export { routes, onRouteBefore }
