import { Button, Form, Input, message } from 'antd'
import { useState, useEffect } from 'react'
import * as API from '@/types/api'
import api from '@/api'
import authorization from '@/store/modules/authorization'
let timeChange: any
function Index(props: any) {
  const [form] = Form.useForm()
  // 秒数（忘记密码）
  const [link, setLink] = useState(true)
  const [count, setCount] = useState(60)
  useEffect(() => {
    clearInterval(timeChange)
    return () => clearInterval(timeChange)
  }, [])

  useEffect(() => {
    if (count > 0 && count < 60) {
      setLink(false)
    } else {
      clearInterval(timeChange)
      setCount(60)
      setLink(true)
    }
  }, [count])

  // 获取验证码
  const handleSendCode = async () => {
    let values = await form.validateFields()
    let params: API.WikaModalSelectionApplicationContractsSystemsAuthUserInfoPhoneCodeRequestDto = {
      phoneNumber: values.phoneNumber,
      clientType: 2,
    }
    const result: any = await api.user.UpdateUserPhoneNumberRequest(params)
    if (result.ok) {
      // countDown()
      timeChange = setInterval(() => setCount((t) => --t), 1000)
      setLink(true)
    } else {
      message.error(result.errors[0])
    }
  }
  // 修改手机号
  const onNext = async () => {
    let values = await form.validateFields()
    let params: API.WikaModalSelectionApplicationContractsSystemsAuthUserInfoPhoneRequestDto = {
      phoneNumber: values.phoneNumber,
      code: values.code,
      clientType: 2,
      refreshToken: authorization.getRefreshToken,
    }
    const result: any = await api.user.UpdateUserPhoneNumberConfirm(params)
    if (result.ok) {
      authorization.refreshAccessToken(
        result.data.accessToken,
        new Date(result.data.accessTokenExpiration).getTime(),
        new Date(result.data.refreshTokenExpiration).getTime()
      )
      form.setFieldsValue({ phoneNumber: '', code: '' })
      props.getUserInfo()
      props.onColse()
    } else {
      message.error(result.errors[0])
    }
  }
  return (
    <div className="register-main form-password">
      <div className="header-title">编辑手机号</div>
      <div className="password-content">
        <Form className="formWrap" name="login" form={form} size="large">
          <div className="info-title psd-title">新手机号: </div>
          <Form.Item name="phoneNumber">
            <Input placeholder="请输入新手机号" className="psd-input" />
          </Form.Item>
          <div className="psd-title psd-line">验证码: </div>
          <Form.Item name="code">
            <Input
              placeholder="请输入验证码"
              className="psd-input"
              prefix={
                <button disabled={!link} onClick={handleSendCode} className="send-wait">
                  {link ? '发送验证码' : `${count}秒后重新发送`}
                </button>
              }
            />
          </Form.Item>
          <Form.Item>
            <Button className="psd-btn" onClick={onNext}>
              保存
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  )
}
export default Index
