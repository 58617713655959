import { observer } from '@/hooks/storeHook'
import { Row, Col } from 'antd'
import './footerBar.less'

function FooterBar() {
  return (
    <div className="footer-main">
      <div className="footer-content">
        <div className="footer-cont">
          <Row style={{ width: '100%' }}>
            <Col span={12}>
              <div className="footer-left">
                <div className="left-title">威卡自动化仪表(苏州)有限公司</div>
                <div className="detail-content">
                  <div className="left-detail left-phone">
                    <div className="left-info">苏州市高新区塔园路81号 215001</div>
                    <div>电话: +86 400 928 9600</div>
                  </div>
                  <div className="left-detail">
                    <div className="left-info">威卡中国总部 - 中国</div>
                    <div>邮箱: Info@wika.cn</div>
                  </div>
                </div>
              </div>
            </Col>
            <Col span={12}>
              <div className="footer-right">
                <div className="right-href">
                  <a href="#/account-info" className="right-link">
                    账户信息
                  </a>
                  <a href="#/policy" className="right-link">
                    隐私政策
                  </a>
                  <a href="#/copyright" className="right-link">
                    版权信息
                  </a>
                  <a href="#/terms">条款信息</a>
                </div>
                <div className="right-tip">
                  © 2022 威卡自动化仪表(苏州)有限公司 - 苏ICP备16057664号-1
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  )
}
export default observer(FooterBar)
