/*
 * @Description: store状态管理hook
 * @Author: Larry
 * @Date: 2022-10-14 11:20:31
 * @Last Modified by: Larry
 * @Last Modified time: 2022-10-14 11:35:32
 */
import { useContext } from 'react'
import StoreContext from '@/contexts/storeContext'
import { observer } from 'mobx-react-lite'

function useStore() {
  const store = useContext(StoreContext)
  return store
}

export {
  observer, // 用于监听store数据的改变，同步到组件数据中
  useStore, // 用于获取store数据，看看使用方法
}
