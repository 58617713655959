/* eslint-disable no-unused-vars */
export enum BusinessUnit {
  Thermometer = 1,
  PressureGauge = 2,
  LiquidLevel = 3,
}

export const BusinessUnitName = {
  [BusinessUnit.Thermometer]: '温度',
  [BusinessUnit.PressureGauge]: '压力',
  [BusinessUnit.LiquidLevel]: '液位',
}

export enum BusinessUnitType {
  ElectronicThermometer = 1,
  PressureGauge = 2,
  PressureTransmitter = 3,
  IntelligentPressureTransmitter = 4,
  DiaphragmSeal = 5,
  BimetalThermometer = 6,
}

export const BusinessUnitTypeName = {
  [BusinessUnitType.ElectronicThermometer]: '电子温度计',
  [BusinessUnitType.PressureGauge]: '压力表',
  [BusinessUnitType.PressureTransmitter]: '压力变送器',
  [BusinessUnitType.IntelligentPressureTransmitter]: '智能型压力变送器',
  [BusinessUnitType.DiaphragmSeal]: '隔膜密封',
  [BusinessUnitType.BimetalThermometer]: '双金属温度计',
}

export const BusinessUnitTypeNotShow: BusinessUnitType[] = [
  BusinessUnitType.IntelligentPressureTransmitter,
  BusinessUnitType.DiaphragmSeal,
]

export const BusinessUnitLevelTree: Record<BusinessUnit, BusinessUnitType[]> = {
  [BusinessUnit.Thermometer]: [
    BusinessUnitType.ElectronicThermometer,
    BusinessUnitType.BimetalThermometer,
  ],
  [BusinessUnit.PressureGauge]: [
    BusinessUnitType.PressureGauge,
    BusinessUnitType.PressureTransmitter,
    BusinessUnitType.IntelligentPressureTransmitter,
    BusinessUnitType.DiaphragmSeal,
  ],
  [BusinessUnit.LiquidLevel]: [],
}

export const PreFilterItemsFields: Record<
  keyof PreFilterItems,
  {
    name: str
    options: Partial<
      Record<
        BusinessUnitType,
        | {
            value: num
            label: str
          }[]
        | 'switch'
      >
    >
  }
> = {
  antiExplosionTypes: {
    name: '防爆类型',
    options: {
      [BusinessUnitType.ElectronicThermometer]: [
        { value: 1, label: 'EXI' },
        { value: 2, label: 'EXD' },
      ],
      [BusinessUnitType.PressureTransmitter]: [
        { value: 1, label: '无防爆要求' },
        { value: 2, label: '本安' },
        { value: 4, label: '隔爆' },
      ],
    },
  },
  diameterSizeTypes: {
    name: '表径大小',
    options: {
      [BusinessUnitType.PressureGauge]: [
        { value: 1, label: '50mm' },
        { value: 2, label: '63mm' },
        { value: 4, label: '100mm' },
        { value: 8, label: '160mm' },
      ],
      [BusinessUnitType.BimetalThermometer]: [
        { value: 1, label: '63mm' },
        { value: 2, label: '80mm' },
        { value: 4, label: '100mm' },
      ],
    },
  },
  liquidFilled: {
    name: '是否充液',
    options: {
      [BusinessUnitType.BimetalThermometer]: 'switch',
    },
  },
  liquidMaterialTypes: {
    name: '接液材质',
    options: {
      [BusinessUnitType.PressureGauge]: [
        { value: 1, label: '黄铜压力表' },
        { value: 2, label: '不锈钢压力表' },
      ],
    },
  },
  outputSignalTypes: {
    name: '输出信号',
    options: {
      [BusinessUnitType.PressureTransmitter]: [
        { value: 1, label: '4…20mA，2线输出' },
        { value: 2, label: '0…10V，3线' },
        { value: 4, label: '0…5V，3线' },
        { value: 8, label: '1…5V，3线' },
        { value: 16, label: '1…6V，3线' },
        { value: 32, label: '0.5…45V，3线' },
        { value: 64, label: '0.5…45V比例电压，3线' },
      ],
    },
  },
  pressureMeasurementTypes: {
    name: '压力类型',
    options: {
      [BusinessUnitType.PressureGauge]: [
        { value: 1, label: '表压' },
        { value: 2, label: ' 差压测量' },
      ],
    },
  },
  pressureRangeTypes: {
    name: '压力量程范围',
    options: {
      [BusinessUnitType.PressureTransmitter]: [
        { value: 1, label: '0~100MPa' },
        { value: 2, label: '0~160MPa' },
        { value: 4, label: '0~600MPa' },
        { value: 8, label: '0~1500MPa' },
        { value: 16, label: '真空量程' },
        { value: 32, label: '绝压量程' },
      ],
    },
  },
  processConnectionTypes: {
    name: '过程连接',
    options: {
      [BusinessUnitType.PressureTransmitter]: [
        { value: 1, label: '1/4 NPT' },
        { value: 2, label: '1/4-ISO7 (DIN2999)' },
        { value: 4, label: 'PT 1/4' },
        { value: 8, label: 'G 1/4 B' },
        { value: 16, label: 'M20' },
        { value: 32, label: '1/2 NPT' },
        { value: 64, label: 'G1/2 B' },
      ],
    },
  },
  protectiveCaseTypes: {
    name: '保护管',
    options: {
      [BusinessUnitType.ElectronicThermometer]: [
        { value: 1, label: '不带' },
        { value: 2, label: '整体棒材' },
        { value: 4, label: '管子' },
      ],
    },
  },
  temperatureMeasurerTypes: {
    name: '测温元件',
    options: {
      [BusinessUnitType.ElectronicThermometer]: [
        { value: 1, label: '热电偶' },
        { value: 2, label: '热电阻' },
      ],
    },
  },
  temperatureRangeTypes: {
    name: '温度范围',
    options: {
      [BusinessUnitType.ElectronicThermometer]: [
        { value: 1, label: '-50℃~250℃' },
        { value: 2, label: '-50℃~500℃' },
        { value: 4, label: '-196℃~600℃' },
        { value: 8, label: '-40℃~1200℃' },
      ],
      [BusinessUnitType.BimetalThermometer]: [
        { value: 1, label: '-70 ... +600 °C' },
        { value: 2, label: '-30 ... +500 °C' },
      ],
    },
  },
  testPrecisionTypes: {
    name: '测试精度',
    options: {
      [BusinessUnitType.PressureTransmitter]: [
        { value: 1, label: '0.5%BFSL' },
        { value: 2, label: '0.25%BFSL' },
        { value: 4, label: '0.125%BFSL' },
      ],
    },
  },
  withLCD: {
    name: '带LCD显示',
    options: {
      [BusinessUnitType.ElectronicThermometer]: 'switch',
    },
  },
}
