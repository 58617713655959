/*
 * @Description: 侧边栏
 * @Date: 2022-02-15
 * @LastEditTime: 2022-08-12
 */
import './sideBar.less'
import { Layout } from 'antd'
import { Link, useNavigate } from 'react-router-dom'
import { useState } from 'react'
import { observer } from '@/hooks/storeHook'
import { ReactComponent as UserIcon } from '../../assets/svg/user.svg'
import { ReactComponent as SearchIcon } from '../../assets/svg/search.svg'
import LoginModal from '@/views/login/loginModal'
import authorization from '@/store/modules/authorization'

const { Sider } = Layout
function SideBar() {
  const [isModalOpen, setIsModalOpen] = useState(false)

  const navigate = useNavigate()

  const onhandleClose = () => {
    setIsModalOpen(false)
  }
  // 个人中心
  const goUserInfo = async (e: any) => {
    // navigate('/user')
    if (await authorization.checkLogin()) {
      let a = document.createElement('a')
      a.href = '#/user'
      if (e.ctrlKey || e.metaKey) {
        a.target = '_blank'
      }
      a.click()
    } else {
      setIsModalOpen(true)
    }
  }
  const propData: any = {
    isModalOpen,
    handleClose: onhandleClose,
    type: 'login',
  }

  return (
    <div className="c-PageLayout-sideBar">
      <Layout className="sideBarLayout">
        <Sider trigger={null} collapsible={false} collapsed={true} collapsedWidth="60">
          <div onClick={(e) => goUserInfo(e)}>
            <div className="logoWrap">
              <UserIcon className="logo" />
            </div>
          </div>
          <div className="menuWarp">
            <Link
              className="menu-item product"
              to="/product"
              onClick={() => {
                navigate('/product')
              }}
            >
              产品清单
            </Link>
            <Link
              className="menu-item industry"
              to="/industry"
              onClick={() => {
                navigate('/industry')
              }}
            >
              行业应用
            </Link>
            <Link
              className="menu-item product-code"
              to="/code"
              onClick={() => {
                navigate('/code')
              }}
            >
              产品序列号
            </Link>
            <div className="search-button" onClick={() => navigate('/')}>
              <SearchIcon></SearchIcon>
            </div>
          </div>
        </Sider>
      </Layout>
      <LoginModal {...propData} />
    </div>
  )
}

export default observer(SideBar)
